import {BrowserRouter as Router} from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import Configuration from '../config'
import {Authentication} from '../security'

// let token;
// Authentication.addObserver(user => {
//     token = user.signInUserSession.idToken.jwtToken;
// });

axios.interceptors.request.use(
    config => {
        if (config.url.startsWith(Configuration.API.Endpoint)) {
            return Authentication.currentSession()
                .then(user => {
                    config.headers.Authorization = 'Bearer ' + user.jwtToken;
                    return Promise.resolve(config)
                })
                .catch(() => {
                    console.log("no user");
                    Promise.resolve(config);
                });
        }
        return Promise.resolve(config);
    },
    error => {
        toast.error("Connection error.");
        return Promise.reject(error);
    });

axios.interceptors.response.use(
    response => {
        const {message, successMessage, infoMessage, warningMessage, errorMessage} = response.data;

        if (message) {
            toast.success(message);
        }
        if (successMessage) {
            toast.info(successMessage);
        }
        if (infoMessage) {
            toast.info(infoMessage);
        }
        if (warningMessage) {
            toast.warn(warningMessage);
        }
        if (errorMessage) {
            toast.error(errorMessage);
        }
        return response;
    },
    error => {
        if (!error.response) {
            toast.error("Connection error.");
        } else {
            if (error.response.status === 403 ||
                error.response.status === 401) {
                toast.error(error.response.data.errorMessage || "Session timed out.");
                Authentication.logout();
                Router.history.push('/login?returnUrl=' + window.location.pathname)
            }

            const {errorMessage, message} = error.response.data;
            if (errorMessage || message) {
                toast.error(errorMessage || message);
            } else {
                toast.error("Unexpected error.");
            }
        }
        return Promise.reject(error);
    });