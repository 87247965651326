class Observer {

    user = undefined;
    callbacks = [];

    add = callback => {
        this.callbacks.push(callback);
        callback(this.user);
    };

    remove = callback => {
        const index = this.callbacks.indexOf(callback);
        if (index >= 0) {
            this.callbacks.splice(index, 1);
        }
    };

    update = user => {
        this.user = user;
        return Promise.all([
            this.callbacks.map(callback => callback(this.user))
        ])
    }
}

export const AuthenticationStatusObserver = new Observer();