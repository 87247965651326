import React from 'react'
import {DefaultLayout} from "../layouts";

export default class ErrorPage extends React.Component {

    render() {

        let {message} = this.props;
        return (
            <DefaultLayout>
                <div style={styles.content}>
                    <h1>Error</h1>
                    <p>{message || "Unexpected Error."}</p>
                </div>
            </DefaultLayout>
        )
    }
};

const styles = {
    content: {
        padding: 30,
        borderStyle: "solid",
        borderColor: "#eee",
        borderRadius: 10,
        backgroundColor: "white"
    },
    refresh: {
        marginLeft: 20
    }
};