import React from "react";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {PrivateRoute} from "./security";
import {LoginPage, MainPage, ErrorPage} from "./pages";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap/dist/css/bootstrap.min.css";

require("./utils/axios");

export default class App extends React.Component {

    render() {
        return (
            <React.Fragment>
                <ToastContainer/>
                <Router>
                    <Switch>
                        <Route exact path={"/login"}
                               component={LoginPage}/>
                        <PrivateRoute exact path={"/"}
                                      component={MainPage}/>
                        <ErrorPage/>
                    </Switch>
                </Router>
            </React.Fragment>
        );
    }
}