import React from "react";
import {Redirect, Route} from "react-router-dom";
import {withAuthentication} from "../security";

const PrivateRoute = ({component: Component, user, authentication, ...rest}) => {
    return (
        <Route {...rest} render={
            (props) => {
                if (!user) {
                    return <Redirect to={'/login?returnUrl=' + props.match.url}/>
                } else {
                    return <Component {...props} user={user} authentication={authentication} />
                }
            }
        }/>
    )
};

export default withAuthentication(PrivateRoute);