import React from "react";
import {withRouter} from "react-router-dom"
import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import {DefaultLayout} from "../layouts";
import {withAuthentication} from "../security";
import {toast} from "react-toastify";

class LoginPage extends React.Component {

    state = {
        credentials: {
            username: "",
            password: ""
        }
    };

    handleChange = event => {
        let {credentials} = this.state;
        credentials[event.target.name] = event.target.value;
        this.setState({credentials});
    };

    handleSubmit = event => {
        event.preventDefault();
        const {credentials} = this.state;
        this.setState({loading: true});
        this.props.authentication.login(credentials.username, credentials.password)
            .then(user => {
                console.log("User logged in", user);
                toast.success("User logged in.");
                this.setState({loading: false}, () => this.props.history.push("/"));
            })
            .catch(error => {
                console.log("User login failed", error);
                toast.error("User login failed.");
                this.setState({loading: false});
            });
    };

    render() {

        const {credentials, loading} = this.state;
        return (
            <DefaultLayout>
                <div style={styles.form}>
                    {/*<h1>Login Page</h1>*/}
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <Label>Username</Label>
                            <Input name="username"
                                   value={credentials.username}
                                   disabled={loading}
                                   onChange={this.handleChange}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Password</Label>
                            <Input type="password"
                                   name="password"
                                   disabled={loading}
                                   value={credentials.password}
                                   onChange={this.handleChange}/>
                        </FormGroup>
                        <Button color="primary"
                                disabled={loading}
                                onClick={this.handleSubmit}>
                            Login
                        </Button>
                    </Form>
                </div>
            </DefaultLayout>
        );
    }
}

export default withRouter(withAuthentication(LoginPage));

const styles = {
    form: {
        width: 400,
        padding: 30,
        margin: "auto",
        marginTop: 50,
        borderStyle: "solid",
        borderColor: "#eee",
        borderRadius: 10,
        backgroundColor: "white"
    }
};