import Configuration from "../config";
import axios from "axios";

export default {

    listServers: () => new Promise((resolve, reject) => {
        axios.get(`${Configuration.API.Endpoint}/instances`)
            .then(res => resolve(res.data), err => reject(err));
    }),

    startServer: id => new Promise((resolve, reject) => {
        axios.get(`${Configuration.API.Endpoint}/instances/${id}/start`)
            .then(res => resolve(res.data), err => reject(err));
    }),

    stopServer: id => new Promise((resolve, reject) => {
        axios.get(`${Configuration.API.Endpoint}/instances/${id}/stop`)
            .then(res => resolve(res.data), err => reject(err));
    })
}