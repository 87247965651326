import Amplify, {Auth} from 'aws-amplify';
import Configuration from "../config";
import {AuthenticationStatusObserver} from "./observer";
import {mapCognitoSession, mapCognitoUser} from "./mappers";

Amplify.configure({
    Auth: Configuration.Authentication
});

class AuthenticationProvider {

    lastToken = undefined;

    addObserver = observer => AuthenticationStatusObserver.add(observer);

    removeObserver = observer => AuthenticationStatusObserver.remove(observer);

    updateStatus = user => {
        const token = user ? user.jwtToken : undefined;
        if (this.lastToken === token) return user;
        return AuthenticationStatusObserver.update(user)
            .then(() => {
                this.lastToken = token;
                return user;
            })
            .catch(() => user)
    };

    login = (email, password) => Auth.signIn(email, password)
        .then(user => {
            if (user.challengeName) {
                return user;
            } else {
                const cognitoUser = mapCognitoUser(user);
                return this.updateStatus(cognitoUser);
            }
        });

    completeNewPassword = (user, password, attributes) => Auth.completeNewPassword(user, password, attributes);

    forgotPassword = email => Auth.forgotPassword(email);

    resetPassword = (email, code, newPassword) => Auth.forgotPasswordSubmit(email, code, newPassword);

    // currentSession = () => Auth.currentSession()
    //     .then(mapCognitoSession)
    //     .then(this.updateStatus);

    currentSession = () => Auth.currentSession()
        .then(mapCognitoSession)
        .then(this.updateStatus);

    logout = global => Auth.signOut(global)
        .then(() => this.updateStatus());

    getCurrentUser = () => Auth.currentAuthenticatedUser();

    getCurrentSession = () => Auth.currentSession();
}

export const Authentication = new AuthenticationProvider();