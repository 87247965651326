import React from "react";
import {Authentication} from "./authentication";

const AuthenticationContext = React.createContext();

const AuthenticationConsumer = AuthenticationContext.Consumer;

export class AuthenticationProvider extends React.Component {

    state = {
        ready: false
    };

    refreshToken = () => {

        console.log("Authentication check...");
        Authentication.currentSession()
            .then(user => {
                if (user) {
                    console.log("Current session", user);
                    return user;
                }
                console.log("Session not found, trying to return user instead...");
                return Authentication.currentUser().then(user => {
                    console.log("Current user", user);
                    return user;
                }, error => {
                    console.log("Current user error", error);
                    return null;
                });
            }, error => {
                console.log("Current session error", error);
                return null;
            })
            .then(user => {
                console.log("Authentication check completed", user);
                this.setState({user, ready: true}, () => this.authenticationStatusChanged(user));
            })
            .catch(error => {
                console.log("Authentication check error", error);
                this.setState({user: undefined, ready: true})
            });
    };

    authenticationStatusChanged = user => this.setState({user});

    componentWillMount() {
        Authentication.addObserver(this.authenticationStatusChanged);
        this.refreshToken();
        this.refreshInterval = setInterval(this.refreshToken, 600000); // every 10 minutes
    }

    componentWillUnmount() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
        Authentication.removeObserver(this.authenticationStatusChanged);
    }

    render() {
        let {user, ready} = this.state;
        if (!ready) return null;
        return (
            <AuthenticationContext.Provider value={{user, authentication: Authentication}}>
                {this.props.children}
            </AuthenticationContext.Provider>
        )
    }
}

export const withAuthentication = WrappedComponent => props => (
    <AuthenticationConsumer>
        {
            ({user, authentication}) => (
                <WrappedComponent {...props}
                                  user={user}
                                  authentication={authentication}/>
            )
        }
    </AuthenticationConsumer>
);