export default {
    API: {
        Endpoint: "https://sdhv44qlvl.execute-api.us-east-1.amazonaws.com/prod"
    },
    Authentication: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_LByr8bml9',
        userPoolWebClientId: '3umn0muru801doo058f79c6t9n',
        mandatorySignIn: false,
        // cookieStorage: {
        //     domain: '.aws-scheduler.asyasoft.net',
        //     path: '/',
        //     expires: 365,
        //     secure: true
        // },
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    }
}