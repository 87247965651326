let moment = require('moment');
// moment.locale('tr');

export const formatDate = (date) => {

    if (!date) return '';
    return moment(date).format('L');
};

export const formatTime = (date) => {

    if (!date) return '';
    return moment(date).format('LT');
};

export const formatDateTime = (date) => {

    if (!date) return '';
    return moment(date).format('LLL');
};

export const formatRelativeTime = (date) => {

    if (!date) return '';
    return moment().to(date);
};