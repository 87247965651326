export const mapCognitoUser = user => {
    if (user && user.signInUserSession && user.signInUserSession.idToken) {
        const {jwtToken, payload} = user.signInUserSession.idToken;
        return {
            ...payload,
            username: payload["cognito:username"],
            jwtToken
        };
    }
};
export const mapCognitoSession = session => {
    if (session && session.idToken) {
        const {jwtToken, payload} = session.idToken;
        return {
            ...payload,
            username: payload["cognito:username"],
            jwtToken
        };
    }
};