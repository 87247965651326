import React from "react";
import {DefaultLayout} from "../layouts";
import {Button, Table} from "reactstrap";
import {Data} from "../data";
import {formatRelativeTime} from "../utils/date";

export default class MainPage extends React.Component {

    state = {
        servers: undefined
    };

    loadServers = () => {
        this.setState({loading: true});
        Data.listServers()
            .then(servers => this.setState({servers, loading: false}))
            .catch(error => {
                console.log("List servers error.", error);
                this.setState({loading: false});
            });
    };

    startServer = id => Data.startServer(id).then(() => this.loadServers());

    stopServer = id => Data.stopServer(id).then(() => this.loadServers());

    renderTable = () => {

        const {servers, loading} = this.state;
        if (loading) {
            return <div>Loading...</div>
        } else if (!servers || !servers.length) {
            return <div>No servers found.</div>
        }

        return (
            <Table>
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Instance Id</th>
                    <th>Instance Type</th>
                    <th>Launched At</th>
                    <th>Public IP Address</th>
                    <th>State</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {
                    servers.map(server => (
                        <tr key={server.id}>
                            <td>{server.name}</td>
                            <td>{server.id}</td>
                            <td>{server.type}</td>
                            <td>{formatRelativeTime(server.launchedAt)}</td>
                            <td>{server.ip}</td>
                            <td>{server.state}</td>
                            <td>
                                {
                                    server.state === "running"
                                        ? (
                                            <Button color="danger"
                                                    onClick={() => this.stopServer(server.id)}>
                                                Stop
                                            </Button>
                                        )
                                        : (
                                            <Button color="success"
                                                    onClick={() => this.startServer(server.id)}>
                                                Start
                                            </Button>
                                        )
                                }
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        );
    };

    componentDidMount() {
        this.loadServers();
    }

    render() {

        return (
            <DefaultLayout>
                <div style={styles.content}>
                    <h1>
                        Amazon Servers
                        <Button color="secondary"
                                style={styles.refresh}
                                onClick={this.loadServers}>
                            Refresh
                        </Button>
                    </h1>
                    {this.renderTable()}
                </div>
            </DefaultLayout>
        );
    }
};
const styles = {
    content: {
        padding: 30,
        borderStyle: "solid",
        borderColor: "#eee",
        borderRadius: 10,
        backgroundColor: "white"
    },
    refresh: {
        marginLeft: 20
    }
};