import React from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, Container
} from "reactstrap";
import {withAuthentication} from "../security";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";

class DefaultLayout extends React.Component {

    state = {
        isOpen: false
    };

    logout = () => {
        this.props.authentication.logout().then(() => toast.success("User logged out."));
    };

    toggle = ()  => this.setState({isOpen: !this.state.isOpen});

    render() {
        const {user} = this.props;
        return (
            <React.Fragment>
                <Navbar color="light" light expand="md">
                    <Container>
                        <NavbarBrand>
                            <Link to="/">
                                <img src={require("../assets/images/logo.png")}
                                     style={styles.logo}
                                     alt="logo"/>
                            </Link>
                            AWS Scheduler
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} />
                        {
                            user &&
                            <Collapse isOpen={this.state.isOpen} navbar>
                                <Nav className="ml-auto" navbar>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            {user.username}
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem onClick={this.logout}>
                                                Logout
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Nav>
                            </Collapse>
                        }
                    </Container>
                </Navbar>
                <Container style={styles.content}>
                    {this.props.children}
                </Container>
            </React.Fragment>
        );
    }
}

export default withAuthentication(DefaultLayout);

const styles = {
    content: {
        marginTop: 30
    },
    logo: {
        height: 20,
        marginTop: -4,
        marginRight: 10
    }
};